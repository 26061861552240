import { getRootLogger } from 'fe-common-logger-core'
import { getStore, setRootLogger } from '../ddWindow'

const setupLogger = () => {
  const rootLogger = getRootLogger({
    remote: {
      getToken: () => window.keycloak.token ?? '',
      data: () => ({
        name: getStore().getState().user.get('userName'),
        organization: getStore().getState().user.get('activeOrganization')
      })
    }
  })
  setRootLogger(rootLogger)
  return rootLogger
}

const rootLogger = setupLogger()

const logger = rootLogger.getLogger('app')

export default logger
