import { updateToken } from 'fe-common-xhr'
// Script (SN_CSM_EC) and variable (SN_CSM_EC_MODULE_ID) injected using Google Tag Manager

export const initEngagementMessenger = () => {
  if (window.SN_CSM_EC) {
    window.SN_CSM_EC.init({
      moduleID: window.SN_CSM_EC_MODULE_ID,
      loadFeature: window.SN_CSM_EC.loadEMFeature(),
      tokenCallBack: async () => {
        try {
          await updateToken()
          const { idToken } = window.keycloak
          return Promise.resolve(idToken) // eslint-disable-line
        } catch (error) {
          throw new Error('Failed to update token for Engagement messenger')
        }
      }
    })
  }
}

export const engagementMessengerOnLogin = () => {
  if (window.SN_CSM_EC) {
    window.SN_CSM_EC.onLogin()
  }
}
