import { pathToRegexp } from 'path-to-regexp'
import { getProduct } from './api/product'
import { getActiveOrganization, hasValidSetup, isWithoutRolesAndNotOnSelfService } from './roles'
import { getWorkOrderCards, getWorkorder } from './api/workorder'
import { matchUrl, redirectToSelfService, navigateToUrl, validUUIDRegExp } from './helpers'
import { Detail } from './types'

const guardPath = {
  dmsVin: pathToRegexp('/VIN/:vin'),
  dmsWorkOrder: pathToRegexp('/serviceAdvisor/workorders/:workOrderId'),
  product: pathToRegexp('/serviceAdvisor/products/:productId'),
  workOrder: pathToRegexp('/serviceAdvisor/products/:productId/workorders/:workOrderId')
}

export const redirectUserToSelfServiceRule = async () => {
  if (isWithoutRolesAndNotOnSelfService() || !hasValidSetup()) {
    redirectToSelfService()
  }
  return Promise.resolve(false)
}

export const redirectVinToProductRule = async (detail: Detail) => {
  const { newUrl } = detail
  const matchResult = matchUrl(newUrl, guardPath.dmsVin)

  if (matchResult) {
    const [, vin] = matchResult
    const product = await getProduct(vin)

    if (product) {
      navigateToUrl(`/serviceAdvisor/products/${product[0]?.id}`)
    }

    return true
  }

  return false
}

export const redirectWorkorderToProductRule = async (detail: Detail) => {
  const { newUrl } = detail
  const matchResult = matchUrl(newUrl, guardPath.dmsWorkOrder)

  if (matchResult) {
    const [, workOrderId] = matchResult

    if (!validUUIDRegExp.test(workOrderId)) {
      navigateToUrl(`/serviceAdvisor/products`)
    } else {
      const { id, productId } = await getWorkorder(workOrderId)
      if (id && productId) {
        navigateToUrl(`/serviceAdvisor/products/${productId}/workorders/${id}`)
      }
    }

    return true
  }

  return false
}

export const redirectProductToProductsOnLoadRule = (detail: Detail) => {
  const { oldUrl, newUrl } = detail

  // only on initial load of app
  if (oldUrl === newUrl) {
    const matchResult = matchUrl(newUrl, guardPath.product)

    if (matchResult) {
      const [, productId] = matchResult
      if (!validUUIDRegExp.test(productId)) {
        navigateToUrl(`/serviceAdvisor/products`)
      }

      return Promise.resolve(true)
    }
  }

  return Promise.resolve(false)
}

export const guardWorkOrderPathOnLoadRule = async (detail: Detail) => {
  const { oldUrl, newUrl } = detail

  // only on initial load of app
  if (oldUrl === newUrl) {
    const matchResult = matchUrl(newUrl, guardPath.workOrder)

    if (matchResult) {
      const [, productId, workOrderId] = matchResult

      if (!validUUIDRegExp.test(productId)) {
        navigateToUrl(`/serviceAdvisor/products`)
        return true
      }

      if (!validUUIDRegExp.test(workOrderId)) {
        navigateToUrl(`/serviceAdvisor/products/${productId}`)
        return true
      }

      const cards = await getWorkOrderCards(productId)
      const workorderCard = cards?.find((it) => it.workOrderId === workOrderId)
      if (!workorderCard) {
        navigateToUrl(`/serviceAdvisor/products/${productId}`)
        return true
      }

      if (workorderCard.invoiceTime) {
        navigateToUrl(`/serviceAdvisor/products/${productId}/workorders/${workOrderId}/readonly`)
        return true
      }

      if (workorderCard.organizationId !== getActiveOrganization()) {
        navigateToUrl(`/serviceAdvisor/products/${productId}`)
      }
    }
  }

  return false
}

const routeRules: ((detail: Detail) => Promise<boolean>)[] = [
  redirectUserToSelfServiceRule,
  redirectVinToProductRule,
  redirectProductToProductsOnLoadRule,
  redirectWorkorderToProductRule,
  guardWorkOrderPathOnLoadRule
]

export default routeRules
